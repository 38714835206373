 /*--- isotope with Block ---*/
$(window).load(function() {
	var colWidth = $('.one-fourth').width();

    var masonryBlocks = $('.masonryBlocks');
    // Masonry Layout Object
    var masonryLayout = {
        itemSelector: '.bloc',
        percentPosition: true,
		horizontalOrder: true,
        masonry: {
            // use outer width of grid-sizer for columnWidth
           gutter: 20,
           columnWidth: colWidth
        }
    }

    // Images Loaded Ensure everything is preloaded before init
    masonryBlocks.imagesLoaded(function(){
        masonryBlocks.isotope(masonryLayout).addClass('isotope');
        masonryBlocks.isotope('layout');
    });    
});


 /*--- isotope with Tabs ---*/
$(window).load(function() {

    var $container = $('.masonryBox'),
        filters = {};

    $container.isotope({
        itemSelector: '.isotopeItems',
        masonry: {
            columnWidth: 0,
        }
    });    

    // filter buttons
    $('.filterItem a').click(function () {
        var $this = $(this);
        // don't proceed if already selected
        if ($this.hasClass('selected')) {
            return;
        }

        var $optionSet = $this.parents('.option-set');
        // change selected class
        $optionSet.find('.selected').removeClass('selected');
        $this.addClass('selected');

        // store filter value in object
        // i.e. filters.color = 'red'
        var group = $optionSet.attr('data-filter-group');
        filters[group] = $this.attr('data-filter-value');
        // convert object into array
        var isoFilters = [];
        for (var prop in filters) {
            isoFilters.push(filters[prop])
        }
        var selector = isoFilters.join('');
        $container.isotope({ filter: selector });

        return false;
    });

});