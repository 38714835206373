$(function(){
    // ACCORDION 1
    $('.acc header').click(function(){
        var parent = $(this).parent();
        parent.siblings().find('footer').hide();
        parent.siblings().removeClass('active');
        parent.toggleClass('active');
        parent.find('footer').stop().slideToggle();
    });
     

    // Top Search Accordion
    $('.top-search .tps-icon').click(function(){
        var parent = $(this).parent();
        parent.toggleClass('active');
        parent.find('.tps-form').stop().slideToggle();
    });

    // ACCORDION
    if ($(".stepBlocks .acc").length > 0) {
        $(".stepBlocks .acc:first-child").addClass('active');
        $(".stepBlocks .acc:first-child").find("footer").css("display", "block");
    }   


    // ACCORDION Toggle 2
    $('.listBlock a.cta').click(function(){
        var parent = $(this).parent();
        parent.find('.content .details').slideToggle();
    });


    // Equalize product block heights
    $('.productBlocks, .gridBlocks').imagesLoaded(function(){
        $(window).resize(function(){
            equalize($('.productBlocks:not(.gridBlocks):not(.threeBlock)'), $('.product') );
            equalize($('.gridBlocks:not(.gridBlocks):not(.threeBlock)'), $('.item') );
        }).resize();
    });

});


//Responsiv Tabs with Mobile Accordion Mode
$(document).ready(function() {
    //Horizontal Tab
    $('#parentHorizontalTab').easyResponsiveTabs({
        type: 'default', //Types: default, vertical, accordion
        width: 'auto', //auto or any width like 600px
        fit: true, // 100% fit in a container
        tabidentify: 'hor_1', // The tab groups identifier
        activate: function(event) { // Callback function if tab is switched
            var $tab = $(this);
            var $info = $('#nested-tabInfo');
            var $name = $('span', $info);
            $name.text($tab.text());
            $info.show();
        }
    }); 

    // Click To Scroll Top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('#back-to-top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 1200);
        return false;
    });       
         
});






